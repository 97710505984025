.stepper_text_header {
  color: #48768c;
  letter-spacing: -2%;
  line-height: -1.5%;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 1rem;
}

.stepper_text_body {
  color: #48768c;
  letter-spacing: -2%;
  line-height: -1.5%;
  font-weight: 600;
  font-size: 14.5px;
  margin-bottom: 0.5rem;
}

.stepper_text_subbody {
  color: #48768c;
  letter-spacing: -2%;
  line-height: -1.5%;
  font-weight: 600;
  font-size: 13.55px;
  margin-top: 0.09rem;
  padding-bottom: 0.5rem;
}

.stepper_text_subbody2 {
  color: #48768c;
  letter-spacing: -2%;
  line-height: -1.5%;
  font-weight: 600;
  font-size: 13.25px;
  margin-top: 0.09rem;
  padding-bottom: 1.5rem;
  white-space: nowrap;
}

.review_text_header {
  color: #48768c;
  font-weight: 600;
  font-size: 16.5px;
  margin-top: 0.25rem;
  margin-bottom: 1rem;
  text-align: left;
  padding: 0.25rem;
}

.review_features_header {
  color: #48768c;
  font-weight: 600;
  font-size: 16.5px;
  margin-bottom: 0.65rem;
  text-align: left;
  padding: 0.25rem;
  margin-top: 0.75rem;
}

.review_text_subheader {
  color: #48768c;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 0.75rem;
  text-align: left;
}

.review_text_body {
  color: #48768c;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  text-align: left;
}

.review_text_container {
  background-color: #f2f9ff;
  border: 1.25px solid rgba(173, 216, 230, 0.5);
  border-radius: 14px;
  padding: 0.5rem;
  margin-top: 1.5rem;
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 1rem;
}

.review_text_card {
  padding: 1rem;
  border-radius: 8px;
  background-color: #e6f2ff; /* Slightly different shade of the container color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 0.5rem;
}

.location_text_card {
  border-radius: 8px;
  background-color: #e6f2ff; /* Slightly different shade of the container color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1.25px solid rgba(173, 216, 230, 0.5);
  padding: 0.5rem;
}
