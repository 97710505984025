/* New CSS for AddNewNotification Goes Here */

.field_title_container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  min-width: 30%;
  max-width: 30%;
  border-radius: 8px;
  height: 35px;
  background-color: #f2f9ff;
}

.title_name {
  margin-left: 1px;
  font-weight: 600;
  font-size: 15px;
  font-style: italic;
  color: #747c86;
}

.saveNotificationButton {
  width: auto;
  color: #48768c;
  font-weight: 550;
  font-size: 14px;
  border: 0.75px solid #48768c;
  border-radius: 6px;
  background-color: white;
  transition: box-shadow 0.3s ease; /* Adds smooth transition */
  font-family:
    Source Sans Pro,
    sans-serif;

  /* Box shadow for subtle effect */
  box-shadow:
    0 4px 6px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.08);
}

.previewNotificationButton {
  width: auto;
  color: #48768c;
  font-weight: 550;
  font-size: 14px;
  border: 0.75px solid #48768c;
  border-radius: 6px;
  background-color: white;
  transition: box-shadow 0.3s ease; /* Adds smooth transition */
  font-family:
    Source Sans Pro,
    sans-serif;

  /* Box shadow for subtle effect */
  box-shadow:
    0 4px 6px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.08);
}

/*  CSS Values Used in UserNotifications.js */

.manage_notifications_card {
  padding: 1rem;
  width: 100%;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  border: 1px solid lightgray;
}

.manage_notifications_header {
  margin-bottom: 5px;
  color: #48768c;
  font-weight: 600;
  font-size: 18.5px;
}





.field_name_container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  min-width: 65%;
  max-width: 65%;
  border-radius: 8px;
  height: 35px;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(173, 216, 230, 0.7);
}

.field_type_selector {
  display: flex;
  flex-direction: row;
  font-weight: 600;
  background-color: transparent;
  font-size: 14px;
  align-items: center;
  justify-content: flex-end;
  border: 1px solid rgba(173, 216, 230, 0.7);
}

.add_optional_container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  min-width: 65%;
  max-width: 65%;
  border-radius: 8px;
  height: 40px;
  background-color: #f2f9ff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(173, 216, 230, 0.7);
}

.field_name {
  margin-left: 1px;
  font-weight: 600;
  font-size: 17px;
  font-style: italic;
  color: #747c86;
}

.new_field_name {
  margin-left: 1px;
  font-weight: 600;
  font-size: 17px;
  font-style: italic;
  color: #747c86;
}

.optional_field_name {
  margin-left: 1px;
  font-weight: 600;
  font-size: 15px;
  font-style: italic;
  color: #747c86;
}

.field_icon {
  margin-right: 15px;
}

.field_title {
  margin-left: 10px;
  font-weight: 700;
  font-size: 14.5px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #747c86;
  align-items: center;
}

.field_select_title {
  margin-left: 10px;
  font-weight: 700;
  font-size: 14px;
}

.default_field_icon {
  border: 1px solid #747c86;
  margin-top: 2px;
  width: auto;
  height: 24px;
  border-radius: 6px;
  background-color: #ffffff;
}

.popover_field_icon {
  border: 1px solid #747c86;
  margin-top: 2px;
  width: auto;
  height: 24px;
  border-radius: 6px;
  background-color: #ffffff;
  cursor: pointer;
}

.change_field_icon {
  border: 1px solid #747c86;
  margin-top: 2px;
  width: auto;
  height: 22px;
  border-radius: 6px;
  background-color: #ffffff;
  color: #48768c;
}

.new_field_icon {
  border: 1px solid #747c86;
  width: auto;
  height: 24px;
  border-radius: 6px;
  background-color: #ffffff;
  cursor: pointer;
}

.optional_field_icon {
  margin-top: 2px;
  width: auto;
  height: 23px;
  padding: 1px;
  border-radius: 6px;
  background-color: transparent;
  cursor: pointer;
  margin-left: -2px;
}

.optional_field_title {
  margin-left: 5px;
  font-weight: 600;
  font-size: 13.5px;
  color: #747c86;
  align-items: center;
  align-self: center;
}

.custom_message_field {
  flex-direction: column;
  padding-top: 5px;

  padding-left: 5px;
  width: 55%;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  height: 290px;
  margin-top: 10px;
}

.field_title_date_time {
  margin-left: 5px;
  font-weight: 700;
  font-size: 15px;
  padding-top: 3px;
}

.field_name_container_timer {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  padding-top: 22px;
  padding-bottom: 22px;
  width: 55%;
  max-width: 55%;
  border-radius: 8px;
  height: 40px;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
}

.custom-tooltip .MuiTooltip-tooltip {
  background-color: #ffffff;
}

.popover_save_button {
  align-items: center;
  font-size: 21px;
  border: 1px solid #747c86;
  border-radius: 5px;
  background-color: #ffffff;
  justify-content: flex-end;
}

.popover_cancel_button {
  align-items: center;
  font-size: 21px;
  border: 1px solid #747c86;
  border-radius: 5px;
  background-color: #ffffff;
  color: #6595ab;
  justify-content: flex-end;
}

.popover_edit_button {
  align-items: center;
  font-size: 21px;
  border: 1px solid #747c86;
  border-radius: 5px;
  background-color: #ffffff;
  color: #48768c;
  justify-content: flex-end;
}

.field_save_button {
  align-items: center;
  font-size: 17px;
  border-radius: 5px;
  background-color: transparent;
  color: #48b09b;
  margin-right: 0px;
}

.field_cancel_button {
  align-items: center;
  font-size: 21px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #8c4350;
}
.popover_back_button {
  align-items: center;
  font-size: 15px;
  background-color: #f3f9ff;
  color: #517d92;
  border: 1px solid #747c86;
  border-radius: 5px;
}

.icon_save_button {
  align-items: center;
  font-size: 17px;
  border-radius: 5px;
  background-color: transparent;
  color: #48b09b;
  margin-right: 0px;
}

.icon_cancel_button {
  align-items: center;
  font-size: 21px;
  border-radius: 5px;
  background-color: transparent;
  color: #8c4350;
}

._2BBB9E {
  align-items: center;
  background-color: #219b83; /* Changed to color */
  color: #2bbb9e; /* Changed to background-color */
  padding: 0;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added box shadow */
  cursor: pointer;
  border: 1px solid #ffffff;
  height: 19px;
  width: 19px;
}

._7589A2 {
  align-items: center;
  background-color: #667991; /* Changed to color */
  color: #7589a2; /* Changed to background-color */
  padding: 0;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added box shadow */
  cursor: pointer;
  border: 1px solid #ffffff;
  height: 19px;
  width: 19px;
}

._a1848f {
  align-items: center;
  background-color: #8c6f7a; /* Changed to color */
  color: #a1848f; /* Changed to background-color */
  padding: 0;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added box shadow */
  cursor: pointer;
  border: 1px solid #ffffff;
  height: 19px;
  width: 19px;
}

._8C4350 {
  align-items: center;
  background-color: #5c2933; /* Changed to color */
  color: #8c4350; /* Changed to background-color */
  padding: 0;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added box shadow */
  cursor: pointer;
  border: 1px solid #ffffff;
  height: 19px;
  width: 19px;
}


._D49A89 {
  align-items: center;
  background-color: #B76F59; /* Changed to color */
  color: #D49A89; /* Changed to background-color */
  padding: 0;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added box shadow */
  cursor: pointer;
  border: 1px solid #ffffff;
  height: 19px;
  width: 19px;
}

/*

ADDITIONAL COLORS IN COLOR PICKER TO USE IN THE FUTURE

._7589a2 {
    align-items: center;
    background-color: #5D6E84;
    color: #7589A2;
    padding: 0;
    border-radius: 3px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    border: 1px solid #FFFFFF;
}

._a1848f {
    align-items: center;
    background-color: #8B6976;
    color: #a1848f;
    padding: 0;
    border-radius: 3px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    border: 1px solid #FFFFFF;
}

._7d7371 {
    align-items: center;
    background-color: #565656;
    color: #7D7371;
    padding: 0;
    border-radius: 3px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    border: 1px solid #FFFFFF;
}

._7f8487 {
    align-items: center;
    background-color: #565656;
    color: #7F8487;
    padding: 0;
    border-radius: 3px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    border: 1px solid #FFFFFF;
}

._b5ad8e {
    align-items: center;
    background-color: #6b6b6b;
    color: #B5AD8E;
    padding: 0;
    border-radius: 3px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    border: 1px solid #FFFFFF;
}

._827b76 {
    align-items: center;
    background-color: #5e5e5e;
    color: #827B76;
    padding: 0;
    border-radius: 3px;
    cursor: pointer;
    border: 1px solid #FFFFFF;
}

._a3847d {
    align-items: center;
    background-color: #635959;
    color: #A3847D;
    padding: 0;
    border-radius: 3px;
    cursor: pointer;
    border: 1px solid #FFFFFF;
}

._858ba9 {
    align-items: center;
    background-color: #5e5e82;
    color: #858BA9;
    padding: 0;
    border-radius: 3px;
    cursor: pointer;
    border: 1px solid #FFFFFF;
}

._a89a8c {
    align-items: center;
    background-color: #65654f;
    color: #A89A8C;
    padding: 0;
    border-radius: 3px;
}

._808a7c {
    align-items: center;
    background-color: #595943;
    color: #808A7C;
    padding: 0;
    border-radius: 3px;
    cursor: pointer;
}

._9f7f75 {
    align-items: center;
    background-color: #6b5e59;
    color: #9F7F75;
    padding: 0;
    border-radius: 3px;
    cursor: pointer;
}

._827d89 {
    align-items: center;
    background-color: #595964;
    color: #827D89;
    padding: 0;
    border-radius: 3px;
    cursor: pointer;
}

._7d8585 {
    align-items: center;
    background-color: #595964;
    color: #7D8585;
    padding: 0;
    border-radius: 3px;
    cursor: pointer;
}

._a97d75 {
    align-items: center;
    background-color: #635959;
    color: #A97D75;
    padding: 0;
    border-radius: 3px;
    cursor: pointer;
}

._828ca0 {
    align-items: center;
    background-color: #5e5e82;
    color: #828CA0;
    padding: 0;
    border-radius: 3px;
    cursor: pointer;
}
*/

.icon-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
}

.icon-item {
  width: 100%;
}

.show-tooltip {
  cursor: pointer;
}

.show-tooltip:hover::before {
  content: attr(title);
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 4px;
  border-radius: 4px;
  z-index: 1000;
  display: block;
  width: max-content;
}

@keyframes blinkTextCursor {
  from {
    border-right-color: hsl(0, 0%, 80%);
  }
  to {
    border-right-color: transparent;
  }
}

.confirmNotificationButton {
  width: auto;
  color: #48768c;
  font-weight: 600;
  font-size: 12.5px;
  border: 0.75px solid #48768c;
  padding: 6px;
  border-radius: 6px;
  background-color: white;
  transition: box-shadow 0.3s ease; /* Adds smooth transition */
  margin-top: 2rem;

  /* Box shadow for subtle effect */
  box-shadow:
    0 4px 6px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.08);
}

.setupLaterButton:hover {
  /* Slightly stronger shadow on hover */
  box-shadow:
    0 8px 12px rgba(0, 0, 0, 0.15),
    0 3px 6px rgba(0, 0, 0, 0.12);
}
