.icon_button {
  position: absolute;
  right: 4px;
  top: 7px;
  color: #7589a2;
  background-color: transparent;
  z-index: 1;
  border-radius: 12px;
  width: 35px;
  height: 35px;
  padding: 3px;
  margin-left: 0.5px;
  margin-right: 0.5px;
  margin-top: 0.25px;
  margin-bottom: 0.25px;
}

.close_icon {
  font-size: 28px;
}

.dialog_title {
  display: flex;
  align-items: flex-start;
  height: 60px;
  background-color: #ffffff;
  padding-top: 10px;
  padding-bottom: 2.45px;
  color: #48768c;
  height: 70px;
}

.dialog_title_icon {
  border: 1px solid #b0bec5;
  margin-left: 5px;
  width: auto;
  height: 32px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 3px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.09);
}

.dialog_content {
  width: 510px;
  background-color: #ffffff;
  padding: 29px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  z-index: 0;
  overflow-y: hidden;
}

.new_notification_header {
  margin-left: 5px;
  font-size: 23px;
  margin-bottom: 16px;
  font-weight: 600;
  letter-spacing: -1%;
  margin-top: 15px;
}

.outlined_input_container {
  border-radius: 20px;
  margin-bottom: 2px;
  width: 100%;
  height: 47px;
}

.input_text {
  margin-top: 2px;
  font-size: 17px;
  font-weight: 500;
  color: #647d9e;
  font-family: "Source Sans Pro", sans-serif;
}

.phone_icon {
  width: auto;
  height: 28px;
  margin-top: 2px;
  margin-left: 2px;
  padding: 3px;
}

.input_label {
  margin-left: 2.5px;
  margin-top: 2px;
  font-weight: 500;
  width: 100px;
  font-size: 16px;
  color: #647d9e;
  margin-right: 3px;
}

.end_adornment_container {
  width: auto;
  border-radius: 6px;
  align-items: center;
  flex-direction: row;
  display: flex;
}

.contacts_icon {
  cursor: pointer;
  background-color: #ffffff;
}

.end_adornment_container:hover {
  transform: scale(1.1);
}

.end_adornment_container:active {
  transform: scale(0.95);
}

.notification_select_container {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-top: 11px;
  padding-bottom: 11px;
  border-radius: 20px;
  width: 100%;
  font-size: 15.5px;
  margin: 0 auto;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 10px;
  justify-content: center;
}

.notify_icon {
  margin-left: 15px;
  width: auto;
  height: 28px;
  padding: 3px;
}

.notification_label {
  font-size: 16px;
  font-weight: 500;
  margin-right: 5.5px;
  margin-left: 5px;
  align-items: center;
  justify-content: center;
}

.notification_text {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  padding-right: 10px;
  font-weight: 600;
  font-size: 14.5px;
  width: 100%;
  align-self: center;
}

.notification_select_popover_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.notification_popover_header {
  color: #48768c;
  font-weight: 600;
  padding-left: 8px;
  padding-right: 20px;
  padding-bottom: "10px";
  line-height: -2%;
}

.notification_title_popover {
  width: 100%;
  cursor: pointer;
  text-align: left;
  font-weight: 600;
  padding-left: 1px;
}

.end_adornment_container:hover {
  transform: scale(1.1);
}

.end_adornment_container:active {
  transform: scale(0.95);
}

.notification_details_container {
  border: 1px solid #b0bec5;
  height: auto;
  border-radius: 15px;
  margin-top: 2px;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
}

.notification_details_label {
  font-weight: 600;
  padding-left: 2px;
  color: #48768c;
  padding-bottom: 10px;
  margin-left: 15px;
  margin-top: 12px;
  letter-spacing: -1.5%;
}

.ticket_number_input {
  margin-left: 25px;
  margin-right: 36px;
  margin-bottom: 20px;
  width: 85%;
  margin-top: 10px;
  color: #ffffff;
  padding-left: "8px";
}

.ticket_icon {
  border: 1px solid #b0bec5;
  width: auto;
  height: 28px;
  border-radius: 6px;
  padding: 1px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.09);
  margin-bottom: 0;
  background-color: #ffffff;
}

/* CSS for balance input */
.timer_container {
  margin-left: 30px;
  margin-right: 26px;
  width: 87%;
  margin-bottom: 1px;
  color: #647d9e;
}

.timer_text_field {
  margin-left: 8px;
  width: 100%;
  margin-top: 2px;
  color: #647d9e;
}

.time_picker_container {
  margin-left: 30px;
  margin-right: 36px;
  width: 87%;
  margin-bottom: 5px;
}

.selected_notification_input {
  margin-top: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #647d9e;
  font-family: "Source Sans Pro", sans-serif;
  margin-left: 2px;
}

.selected_notification_icon {
  border: 1px solid #b0bec5;
  margin-top: 2px;
  width: auto;
  height: 28px;
  border-radius: 10px;
  padding: 1px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.09);
  border-radius: 6px;
  background-color: #ffffff;
}

/* CSS for button container */
.button_container {
  display: flex;
  justify-content: right;
  margin-top: 40px;
  margin-bottom: 20px;
  width: "100%";
}

/* CSS for preview button */
.preview_button {
  font-size: 14.5px;
  line-height: 25px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-top: 2.5px;
  margin-right: 30px;
  margin-bottom: 2px;
  background-color: #48768c;
  border: 1px solid #b0bec5;
  width: 17%;
  border-radius: 10px;
  color: #ffffff;
  text-align: center;
  font-weight: 600;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

/* CSS for disabled preview button */
.preview_button.disabled {
  background-color: #cccccc;
  cursor: default;
  opacity: 0.6;
}

/* Add hover effect only if not disabled */
.preview_button:not(.disabled):hover {
  background-color: #5a8397;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}

/* CSS for schedule button */
.schedule_button {
  font-size: 14.5px;
  line-height: 15px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-top: 2.5px;
  margin-right: 30px;
  margin-bottom: 2px;
  background-color: #ffffff;
  border: 1px solid #b0bec5;
  width: 17%;
  border-radius: 10px;
  color: #48768c;
  text-align: center;
  font-weight: 600;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

/* CSS for disabled preview button */
.schedule_button.disabled {
  background-color: #cccccc;
  cursor: default;
  opacity: 0.6;
}

/* Add hover effect only if not disabled */
.schedule_button:not(.disabled):hover {
  background-color: #5a8397;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}

/* CSS for toggle info button */
.toggle_info_button {
  font-size: 14.5px;
  display: flex;
  line-height: 25px;
  justify-content: center;
  cursor: pointer;
  margin-top: 2.5px;
  margin-right: 30px;
  margin-bottom: 2px;
  background-color: #ffffff;
  border: 1px solid #b0bec5;
  width: 15%;
  border-radius: 10px;
  color: #48768c;
  text-align: center;
  font-weight: 600;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

/* CSS for disabled preview button */
.toggle_info.disabled {
  background-color: #cccccc;
  cursor: default;
  opacity: 0.6;
}

/* Add hover effect only if not disabled */
.toggle_info:not(.disabled):hover {
  background-color: #5a8397;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}

.selected_date_time_text {
  color: #647d9e;
  font-size: 14px;
  padding-top: 2;
  font-weight: 600;
  margin-left: 1;
}

/* CSS for dialog actions */
.dialog_actions {
  display: flex;
  align-items: right;
  justify-content: right;
  width: 100%;
  background-color: #ffffff;
  padding-top: 10px;
  padding-bottom: 22.45px;
  color: #374247;
  padding-right: 25px;
}

/* Hover state for schedule button */
.schedule_button:hover {
  background-color: #ffffff;
}

/* Active state for schedule button */
.schedule_button:active {
  background-color: #ffffff;
}

/* Disabled state for schedule button */
.schedule_button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  margin-top: 4px;
  margin-right: -1px;
  border-radius: 15px;
  letter-spacing: 0.1px;
  width: auto;
  height: auto;
  font-size: 16px;
  font-weight: 500;
}

/* CSS for send notification button */
.send_notification_button {
  border-radius: 15px;
  letter-spacing: 0.1px;
  width: auto;
  height: auto;
  font-size: 16px;
  font-weight: 500;
  background-color: #48768c;
  margin-top: 4px;
  margin-right: -1px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}

.schedule_notification_button {
  border-radius: 15px;
  letter-spacing: 0.1px;
  width: auto;
  height: auto;
  font-size: 16px;
  font-weight: 500;
  background-color: #48768c;
  margin-top: 4px;
  margin-right: -1px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}

/* Hover state for send notification button */
.send_notification_button:hover {
  background-color: #3b6b7d;
}

/* Active state for send notification button */
.send_notification_button:active {
  background-color: #2e7d32;
}

/* Disabled state for send notification button */
.send_notification_button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
